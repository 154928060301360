import { request } from '@/utils/request.js'

//评议|查看 一份试卷
export function getOneRecordApi (markRecordId) {
  return request({
    url: `/exam/score/test/${markRecordId}/record/get`,
    method: 'post',
  })
}

export function updateScoreMarkApi (data) {
  return request({
    url: `/exam/score/test/suggest/update`,
    method: 'post',
    data: data
  })
}

//获取下一份评议卷
export function getNextTestMarkApi (pageIndex, markRecordId, examId) {
  return request({
    url: `/exam/score/test/next/${pageIndex}/${markRecordId}/${examId}/record/get`,
    method: 'post',
  })
}

