<template>
  <div id="app">
    <!-- 视频组件 -->
    <dualVideoCom
      ref="dualVideoCom"
      :mainStreamUrl="mainStreamUrl"
      :subStreamUrl="subStreamUrl"
      :examOperationTest="examOperationTest"
      :openAI="openAI"
      @renderPointFrame="renderPointFrame"
      :showFace="showFace"
      @changeShowFace="(val)=>{
        showFace = val
      }"
    ></dualVideoCom>

    <el-row :gutter="5" id="markingVideoDissentTable">
      <el-col :span="12">
        <!-- ,'backgroundColor':'#dfe6ec' -->
        <el-table
          id="mainTable"
          stripe
          border
          v-loading="loading"
          ref="markingVideoTable"
          :data="leftOperationQuestionList"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="{
            'text-align': 'center',
            backgroundColor: '#dfe6ec',
          }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column
            prop="index"
            label="序号"
            show-overflow-tooltip
            :width="56"
          ></el-table-column>
          <el-table-column label="评分项" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.operationQuestionName }}
              <el-tooltip
                v-if="scope.row.markSuggest.remark"
                effect="dark"
                :content="scope.row.markSuggest.remark"
                placement="bottom"
              >
                <i
                  class="el-icon-warning-outline"
                  style="
                    float: right;
                    line-height: 23px;
                    font-size: 18px;
                    color: #409EFF;
                  "
                ></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="score"
            label="分值"
            show-overflow-tooltip
            :width="80"
          ></el-table-column>
          <el-table-column
            prop="aiScore"
            label="AI初评"
            :width="80"
            align="center"
            v-if="openAI"
          >
            <template slot-scope="scope">{{
              scope.row.aiSupport ? scope.row.aiScore : 0
            }}</template>
          </el-table-column>
          <el-table-column
            v-if="teacherType == 4"
            prop="inputScore"
            label="分数"
            show-overflow-tooltip
            :width="80"
          ></el-table-column>
          <el-table-column
            label="操作"
            :min-width="50"
            show-overflow-tooltip
            v-if="teacherType == 3"
          >
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.inputScore"
                style="width: 60px; margin-left: 10px"
                @input="scoreInput(scope.row, scope.row.inputScore)"
              ></el-input>
              <el-button
                v-throttle
                size="small"
                type="primary"
                style="margin-left: 10px"
                @click="remark(scope.row)"
                >备注</el-button
              >
              <el-button
                v-throttle
                v-if="scope.row.markSuggest.confusion"
                size="small"
                type="warning"
                style="margin-left: 10px"
                @click="unDoubt(scope.$index, scope.row, 'main')"
                >取消</el-button
              >
              <el-button
                v-throttle
                v-if="
                  !scope.row.markSuggest.confusion ||
                  scope.row.markSuggest.confusion == null
                "
                size="small"
                type="warning"
                style="margin-left: 10px"
                @click="doubt(scope.$index, scope.row, 'main')"
                >存疑</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            align="left"
            v-if="openAI"
            label="评分依据"
            show-overflow-tooltip
            :width="120"
            v-once
          >
            <template slot-scope="scope">
              <div
                v-if="
                  renderPointFrame(scope.row.aiResultOperationQuestionItemDTO)
                    .length > 0
                "
                class="pointFrameImg"
                style="height: 54px"
                @click="
                  jumpPointFrameListVideo(
                    renderPointFrame(scope.row.aiResultOperationQuestionItemDTO)
                  )
                "
              >
                <img
                  :src="
                    renderPointFrameImg(
                      renderPointFrame(
                        scope.row.aiResultOperationQuestionItemDTO
                      )
                    )
                  "
                />
              </div>
              <div v-else style="height: 54px"></div>
            </template>
          </el-table-column>
        </el-table>
      </el-col>

      <el-col :span="12">
        <!-- ,'backgroundColor':'#dfe6ec' -->
        <el-table
          id="subTable"
          v-loading="loading"
          stripe
          border
          ref="markingVideoTable"
          :data="rightOperationQuestionList"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="{
            'text-align': 'center',
            backgroundColor: '#dfe6ec',
          }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column
            prop="index"
            label="序号"
            show-overflow-tooltip
            :width="56"
          ></el-table-column>
          <el-table-column label="评分项" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.operationQuestionName }}
              <el-tooltip
                v-if="scope.row.markSuggest.remark"
                effect="dark"
                :content="scope.row.markSuggest.remark"
                placement="bottom"
              >
                <i
                  class="el-icon-warning-outline"
                  style="
                    float: right;
                    line-height: 23px;
                    font-size: 18px;
                    color: #409EFF;
                  "
                ></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="score"
            label="分值"
            show-overflow-tooltip
            :width="61"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="aiScore"
            label="AI初评"
            :width="80"
            v-if="openAI"
          >
            <template slot-scope="scope">{{
              scope.row.aiSupport ? scope.row.aiScore : 0
            }}</template>
          </el-table-column>
          <el-table-column
            v-if="teacherType == 4"
            prop="inputScore"
            label="分数"
            show-overflow-tooltip
            :width="80"
          ></el-table-column>
          <el-table-column
            label="操作"
            :min-width="50"
            show-overflow-tooltip
            v-if="teacherType == 3"
          >
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.inputScore"
                style="width: 60px; margin-left: 10px"
                @input="scoreInput(scope.row, scope.row.inputScore)"
              ></el-input>
              <el-button
                v-throttle
                size="small"
                type="primary"
                style="margin-left: 10px"
                @click="remark(scope.row)"
                >备注</el-button
              >
              <el-button
                v-throttle
                v-if="scope.row.markSuggest.confusion"
                size="small"
                type="warning"
                style="margin-left: 10px"
                @click="unDoubt(scope.$index, scope.row, 'sub')"
                >取消</el-button
              >
              <el-button
                v-throttle
                v-if="
                  !scope.row.markSuggest.confusion ||
                  scope.row.markSuggest.confusion == null
                "
                size="small"
                type="warning"
                style="margin-left: 10px"
                @click="doubt(scope.$index, scope.row, 'sub')"
                >存疑</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            v-if="openAI"
            :width="100"
            label="人工复核"
          >
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.inputScore"
                style="width: 60px"
                @input="scoreInput(scope.row, scope.row.inputScore)"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            align="left"
            v-if="openAI"
            label="评分依据"
            show-overflow-tooltip
            :width="120"
            v-once
          >
            <template slot-scope="scope">
              <div
                v-if="
                  renderPointFrame(scope.row.aiResultOperationQuestionItemDTO)
                    .length > 0
                "
                class="pointFrameImg"
                style="height: 54px"
                @click="
                  jumpPointFrameListVideo(
                    renderPointFrame(scope.row.aiResultOperationQuestionItemDTO)
                  )
                "
              >
                <img
                  :src="
                    renderPointFrameImg(
                      renderPointFrame(
                        scope.row.aiResultOperationQuestionItemDTO
                      )
                    )
                  "
                />
              </div>
              <div v-else style="height: 54px"></div>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>

    <el-row class="marking-video-footer">
      <span>
        考生得分：
        <span>{{ totalScore }}</span> 分
      </span>
      <div v-if="teacherType == 3">
        <el-button v-throttle size="medium" type="primary" @click="submitExit"
          >提交并退出</el-button
        >
        <el-button
          v-throttle
          size="medium"
          type="primary"
          @click="submitContinue"
          >提交并继续</el-button
        >
        <el-button v-throttle size="medium" type="primary" @click="exit"
          >仅退出</el-button
        >
      </div>

      <div v-if="teacherType == 4">
        <el-button v-throttle size="medium" type="primary" @click="getNextTest"
          >下一份</el-button
        >
        <el-button v-throttle size="medium" type="primary" @click="exit"
          >退出</el-button
        >
      </div>
    </el-row>

    <el-row>
      <el-dialog
        title="添加备注"
        :visible.sync="remarkVisible"
        width="30%"
        :before-close="handleClose"
        top="35vh"
      >
        <el-form :model="submitForm" label-width="80px">
          <el-form-item label="备注">
            <el-input
              type="textarea"
              :rows="3"
              v-model="submitForm.remark"
              maxlength="50"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button v-throttle type="primary" @click="save">保存</el-button>
          <el-button v-throttle @click="cancel">取 消</el-button>
        </div>
      </el-dialog>
    </el-row>
  </div>
</template>
<script>
import dualVideoCom from '@/components/dualVideoCom.vue'
import {
  getOneRecordApi,
  updateScoreMarkApi,
  getNextTestMarkApi,
} from '@/api/marking/markingVideoDissent.js'
import { getExamineeAiMarkingApi } from '@/api/marking/ai.js'
import { durationToTimeSting, isEmpty, throttle } from '@/utils/util'
export default {
  name: 'markingVideoDissent',
  components: {
    dualVideoCom,
  },
  data() {
    return {
      mainStreamUrl: '',
      subStreamUrl: '',
      loading: false,

      remarkVisible: false,
      submitForm: {
        remark: '',
      },
      examMarkRecordId: '',
      teacherType: '',
      examOperationTest: {},
      aiMarkResult: {},
      frameList: [],
      //添加备注
      operationQuestionRemark: {},
      operationQuestionList: [],
      leftOperationQuestionList: [],
      rightOperationQuestionList: [],
      totalScore: 0,
      cellSelectedStyle: 'background-color: red;color:#fff;text-align:center;',
      cellNoneStyle: 'border:0;text-align:center;',
      testPageIndex: 0,
      retryCount: 0,

      openAI: false,

      showFace: false
    }
  },

  mounted() {
    this.examMarkRecordId = this.$route.query.examMarkRecordId
    this.teacherType = this.$route.query.teacherType
    console.log(
      `markRecordId:${this.examMarkRecordId},阅卷教师类型:${
        this.teacherType == 3 ? '组长' : '组员'
      }`
    )
    // this.initPlayer(this.mainStreamUrl, this.subStreamUrl)
    this.init()
  },
  beforeDestroy() {
    // this.destroyPlayer()
  },
  methods: {
    init() {
      getOneRecordApi(this.examMarkRecordId)
        .then((res) => {
          if (res.success) {
            let dataList = null
            if (res.data.aiSupport) {
              this.openAI = true
            }
            this.renderData(res.data, dataList)
            // this.getExamineeVideo(res.data.examineeId)
            // 调组件
            this.$refs.dualVideoCom.getExamineeVideo(res.data.examineeId)
          } else {
          }
        })
        .catch(() => {})
    },
    renderData(data, dataList) {
      this.examOperationTest = {}
      this.examOperationTest = data
      if (this.examOperationTest.examName.length > 25) {
        this.examOperationTest.aliasExamName =
          this.examOperationTest.examName.substring(0, 25) + '...'
      } else {
        this.examOperationTest.aliasExamName = this.examOperationTest.examName
      }
      this.examOperationTest.examNameLen =
        this.examOperationTest.examName.length
      this.mainStreamUrl = this.$imageAddress(
        this.examOperationTest.topCameraStream0
      )
      if (!isEmpty(this.examOperationTest.frontCameraStream0)) {
        this.subStreamUrl = this.$imageAddress(
          this.examOperationTest.frontCameraStream0
        )
      } else if (!isEmpty(this.examOperationTest.sideCameraStream0)) {
        this.subStreamUrl = this.$imageAddress(
          this.examOperationTest.sideCameraStream0
        )
      } else {
        this.subStreamUrl = this.$imageAddress(
          this.examOperationTest.frontCameraStream0
        )
      }
      this.$nextTick(() => {
        // this.startPlayer(this.mainStreamUrl, this.subStreamUrl)
        this.$refs.dualVideoCom.initPlayer(
          this.mainStreamUrl,
          this.subStreamUrl
        )
        // // 调组件
        // this.$refs.dualVideoCom.startPlayer(
        //   this.mainStreamUrl,
        //   this.subStreamUrl
        // )
      })
      this.operationQuestionList = []
      this.operationQuestionList = this.examOperationTest.operationQuestions
      this.leftOperationQuestionList = []
      this.rightOperationQuestionList = []
      let totalScore = 0
      let listSize = this.examOperationTest.operationQuestions.length
      let splitPoint = Math.ceil(listSize / 2)
      for (let i = 0; i < listSize; i++) {
        let item = this.examOperationTest.operationQuestions[i]
        item.aiSupport = false
        item.aiScore = 0
        item.aiResultOperationQuestionItemDTO = []
      }
      this.operationQuestionList.forEach((item, index) => {
        if (isEmpty(item)) {
          return
        }
        item.index = index + 1
        this.examOperationTest.markTeacherRecords.forEach((markTeacher) => {
          markTeacher.operationAchievements.forEach((operation) => {
            if (operation.operationQuestionId == item.operationQuestionId) {
              this.$set(item, 'inputScore', operation.score)
            }
          })
        })
        if (index < splitPoint) {
          this.leftOperationQuestionList.push(item)
        } else {
          this.rightOperationQuestionList.push(item)
        }
        if (this.openAI) {
          this.getExamineeAiMarking()
        } else {
          this.loading = false
        }
        if (isEmpty(item.inputScore)) {
          return
        }
        totalScore += Number(item.inputScore)
      })
      this.totalScore = totalScore.toFixed(1)
      this.$nextTick(() => {
        this.operationQuestionList.forEach((item, index) => {
          if (isEmpty(item)) {
            return
          }
          if (
            !isEmpty(item.markSuggest.confusion) &&
            item.markSuggest.confusion
          ) {
            document
              .getElementsByClassName('el-table__row')
              [index].getElementsByClassName('el-table__cell')[1].style =
              this.cellSelectedStyle
          } else {
            document
              .getElementsByClassName('el-table__row')
              [index].getElementsByClassName('el-table__cell')[1].style =
              this.cellNoneStyle
          }
        })
      })
    },

    getExamineeAiMarking() {
      getExamineeAiMarkingApi(
        this.examOperationTest.examId,
        this.examOperationTest.examineeId
      )
        .then((res) => {
          this.loading = false
          if (res.success) {
            this.aiMarkResult = {}
            this.aiMarkResult = res.data
            this.$refs.dualVideoCom.aiMarkResult = this.aiMarkResult
            this.totalScore = 0
            // 获取AI初评的分数
            if (
              this.aiMarkResult.aiResultOperationQuestionItemDTOList &&
              this.aiMarkResult.aiResultOperationQuestionItemDTOList.length > 0
            ) {
              for (
                let i = 0;
                i < this.examOperationTest.operationQuestions.length;
                i++
              ) {
                let optionItem = this.examOperationTest.operationQuestions[i]
                let item =
                  this.aiMarkResult.aiResultOperationQuestionItemDTOList[i]
                optionItem.aiSupport = true
                this.$set(
                  optionItem,
                  'aiOperationItemName',
                  item.aiOperationItemName
                )
                this.$set(optionItem, 'inputScore', item.aiScore)
                this.$set(optionItem, 'aiScore', item.aiScore)
                if (item.aiScore) {
                  this.totalScore += item.aiScore
                }
                optionItem.aiResultOperationQuestionItemDTO = item
                let currentFrameList = this.renderPointFrame(item)
                if (currentFrameList.length > 0) {
                  this.frameList = this.frameList.concat(currentFrameList)
                  this.$refs.dualVideoCom.frameList = this.frameList
                }
              }
              this.frameList.sort(this.frameListSort)
              this.$refs.dualVideoCom.frameList = this.frameList
              let nextFrameStart = 0
              for (
                let i = this.examOperationTest.operationQuestions.length - 1;
                i >= 0;
                i--
              ) {
                let operationQuestion =
                  this.examOperationTest.operationQuestions[i]
                let currentFrameList = this.renderPointFrame(
                  operationQuestion.aiResultOperationQuestionItemDTO
                )
                if (currentFrameList.length > 0) {
                  currentFrameList.sort(this.frameListSort)
                  let firstFrame = currentFrameList[0]
                  if (firstFrame.frameTime) {
                    this.$set(
                      operationQuestion,
                      'frameStartTime',
                      firstFrame.frameTime
                    )
                    this.$set(operationQuestion, 'frameEndTime', nextFrameStart)
                    this.$set(operationQuestion, 'frameFirst', firstFrame)
                    nextFrameStart = operationQuestion.frameStartTime
                  }
                }
              }
            }
          } else {
            if (res.code != '500') {
              this.msgWarning('未获取到考生的AI评分成绩')
            } else {
              this.msgError('获取成绩明细信息失败')
            }
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = true
        })
    },
    scoreInput(row, val) {
      if (isEmpty(val)) {
        this.$set(row, 'inputScore', '')
        this.computeScore()
        return
      }
      val = val.replace(/^\D*(\d*(?:\.\d{0,1})?).*$/g, '$1')
      if (val > row.score) {
        val = row.score
      }
      this.$set(row, 'inputScore', val)
      this.computeScore()
    },
    computeScore() {
      let totalScore = 0
      this.operationQuestionList.forEach((item) => {
        if (isEmpty(item)) {
          return
        }
        if (isEmpty(item.inputScore)) {
          return
        }
        totalScore += Number(item.inputScore)
      })
      this.totalScore = totalScore.toFixed(1)
    },
    //取消存疑
    unDoubt(index, row, txt) {
      switch (txt) {
        case 'main':
          document
            .getElementById('mainTable')
            .getElementsByClassName('el-table__row')
            [index].getElementsByClassName('el-table__cell')[1].style =
            this.cellNoneStyle
          this.$set(row.markSuggest, 'confusion', false)
          break
        case 'sub':
          document
            .getElementById('subTable')
            .getElementsByClassName('el-table__row')
            [index].getElementsByClassName('el-table__cell')[1].style =
            this.cellNoneStyle
          this.$set(row.markSuggest, 'confusion', false)
          break
      }
    },
    //存疑
    doubt(index, row, txt) {
      switch (txt) {
        case 'main':
          document
            .getElementById('mainTable')
            .getElementsByClassName('el-table__row')
            [index].getElementsByClassName('el-table__cell')[1].style =
            this.cellSelectedStyle
          this.$set(row.markSuggest, 'confusion', true)
          break
        case 'sub':
          document
            .getElementById('subTable')
            .getElementsByClassName('el-table__row')
            [index].getElementsByClassName('el-table__cell')[1].style =
            this.cellSelectedStyle
          this.$set(row.markSuggest, 'confusion', true)
          break
      }
    },
    //添加备注
    remark(row) {
      this.submitForm.remark = ''
      if (!isEmpty(row.markSuggest.remark)) {
        this.submitForm.remark = row.markSuggest.remark
      }
      this.operationQuestionRemark = {}
      this.operationQuestionRemark = row
      this.remarkVisible = true
    },
    checkScore(txt) {
      let queryParam = this.getScoreMarkParam()
      try {
        queryParam.operationQuestions.forEach((item, index) => {
          if (isEmpty(item.score) || isNaN(item.score)) {
            throw new Error(`第${index + 1}行分数不能为空`)
          }
        })
        switch (txt) {
          case 'exit':
            this.updateScoreMark()
            break
          case 'continue':
            this.updateScoreMarkContinue()
            break
        }
      } catch (e) {
        this.msgWarning(e.message)
      }
    },
    //提交并退出
    submitExit() {
      this.checkScore('exit')
    },
    updateScoreMark() {
      let queryParam = this.getScoreMarkParam()
      updateScoreMarkApi(JSON.parse(JSON.stringify(queryParam)))
        .then((res) => {
          if (res.success) {
            if (res.data) {
              this.msgSuccess('提交成功')
              this.showFace = false
              this.$router.push({ name: 'markingTest' })
            }
          } else {
            this.msgError(res.msg)
          }
        })
        .catch(() => {})
    },
    //提交并继续
    submitContinue: throttle(function () {
      this.checkScore('continue')
    }, 3000),
    updateScoreMarkContinue() {
      // this.isPicture = false
      this.$refs.dualVideoCom.shutDownIsPicture()
      let queryParam = this.getScoreMarkParam()
      updateScoreMarkApi(JSON.parse(JSON.stringify(queryParam)))
        .then((res) => {
          if (res.success) {
            if (res.data) {
              this.msgSuccess('提交成功')
              this.showFace = false
              this.getNextTestMark()
            }
          } else {
            ++this.retryCount
            if (this.retryCount > 3) {
              this.retryCount = 0
              this.msgError(res.msg)
              return
            }
            setTimeout(() => {
              this.updateScoreMarkContinue()
            }, 1000)
          }
        })
        .catch(() => {})
    },
    getScoreMarkParam() {
      let operationQuestions = []
      this.operationQuestionList.forEach((item) => {
        let operationQuestion = {
          markSuggest: {
            confusion: false,
            remark: '',
          },
          operationQuestionId: 0,
          operationQuestionName: '',
          score: '',
          sortNum: 0,
        }
        operationQuestion.markSuggest.confusion = item.markSuggest.confusion
        operationQuestion.markSuggest.remark = item.markSuggest.remark
        operationQuestion.operationQuestionId = item.operationQuestionId
        operationQuestion.operationQuestionName = item.operationQuestionName
        if (!isEmpty(item.inputScore)) {
          operationQuestion.score = Number(item.inputScore)
        }
        operationQuestion.sortNum = item.sortNum
        operationQuestions.push(operationQuestion)
      })
      let queryParam = {
        examId: this.examOperationTest.examId,
        examineeId: this.examOperationTest.examineeId,
        operationQuestions: operationQuestions,
        operationTestId: this.examOperationTest.operationTestId,
      }
      return queryParam
    },
    //获取一份试卷
    getNextTestMark: throttle(function () {
      let examId = this.$route.query.examId
      if (isEmpty(examId)) {
        return
      }
      getNextTestMarkApi(this.testPageIndex, this.examMarkRecordId, examId)
        .then((res) => {
          if (res.success) {
            this.renderData(res.data)
            // this.getExamineeVideo(res.data.examineeId)
            // 调组件
            this.$refs.dualVideoCom.getExamineeVideo(res.data.examineeId)
          } else {
            if (res.code == '821') {
              this.globalMessageBox(
                '已经是最后一份了',
                '提示',
                '确定',
                '取消',
                'warning'
              )
                .then(() => {
                  this.$router.push({ name: 'markingTest' })
                })
                .catch(() => {})
            }
          }
        })
        .catch(() => {})
    }, 3000),
    //仅退出
    exit() {
      this.globalMessageBox(
        `退出后不保留评卷记录，
      确定退出吗？`,
        '提示',
        '确定',
        '取消',
        'warning'
      )
        .then(() => {
          this.$router.push({ name: 'markingTest' })
        })
        .catch(() => {})
    },
    //获取下一份试卷
    getNextTest() {
      this.getNextTestMark()
      this.testPageIndex++
    },
    save() {
      this.$set(
        this.operationQuestionRemark.markSuggest,
        'remark',
        this.submitForm.remark
      )
      if (!isEmpty(this.submitForm.remark)) {
        this.msgSuccess('保存成功')
      }
      this.remarkVisible = false
      this.submitForm.remark = ''
    },
    handleClose() {
      this.remarkVisible = false
      this.submitForm.remark = ''
    },
    cancel() {
      this.remarkVisible = false
      this.submitForm.remark = ''
    },
    jumpPointFrameListVideo(aiResultOperationQuestionItemDTOList) {
      if (aiResultOperationQuestionItemDTOList.length > 0) {
        this.$refs.dualVideoCom.currentDuration = Math.floor(
          aiResultOperationQuestionItemDTOList[0].frameTime / 1000
        )
        this.$refs.dualVideoCom.customSwitchProgress()
      }
    },
    renderPointFrame(aiResultOperationQuestionItemDTO) {
      let list = aiResultOperationQuestionItemDTO.aiResultItemPointDTOList
      if (list && list.length > 0) {
        for (let index = 0; index < list.length; index++) {
          let eventList = list[index]?.aiResultItemPointEventDTOList
          let aiEventList = eventList?.[0]?.aiResultItemPointEventAiEventDTOList
          let frameList =
            aiEventList?.[0]?.aiResultItemPointEventAiEventFrameDTOList
          if (frameList && frameList.length > 0) {
            frameList.forEach((item) => {
              item.displayContent = list[0].aiPointContent
            })
            return frameList
          }
        }
      }
      return []
    },
    renderPointFrameImg(aiResultItemPointEventAiEventFrameDTOList) {
      if (aiResultItemPointEventAiEventFrameDTOList.length > 0) {
        return this.$imageAddress(
          aiResultItemPointEventAiEventFrameDTOList[0].frameUrl
        )
      } else {
        return ''
      }
    },
    frameListSort(frameFirst, frameSecond) {
      if (frameFirst.frameTime - frameSecond.frameTime < 0) {
        return -1
      } else if (frameFirst.frameTime - frameSecond.frameTime > 0) {
        return 1
      } else {
        return 0
      }
    },
  },
}
</script>
<style lang="scss" scoped>
#app {
  padding: 0 5px;
  margin-top: 2px;
}

.exam-info-badge {
  font: normal bolder 16px 'Source Han Sans CN-Medium, Source Han Sans CN';
  color: #fff;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.marking-video-footer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.marking-video-footer > span {
  color: #303133;
  font-size: 30px;
  font-weight: bold;
  margin-right: 20px;
}

.marking-video-footer > span > span {
  color: #409EFF;
}

::v-deep .el-message-box > .el-message-box__btns > {
  text-align: center;
}

::v-deep .el-input__inner {
  height: 32px;
  text-align: center;
}

::v-deep .el-table {
  padding-bottom: 1px;
}

.marking-hand-box-progress-time {
  width: 150px;
  margin-right: 25px;
}

#markingVideoDissentTable ::v-deep .el-table .el-table__cell {
  padding: 6px 0;
}

.pointFrameImg {
  width: 100%;
  height: 100%;
  border: 1px solid #cbd8f5;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
